<template>
  <div class="screen-box">
    <div class="mapclass">
      <!-- <div class="peN bg-map"></div> -->

      <div class="peN bg-map bg-map1"></div>
      <div class="peN bg-map bg-map2"></div>
      <div class="peN bg-map bg-map3"></div>
      <EchartChinaWord ref="EchartChinaWord"></EchartChinaWord>
    </div>
    <div class="screen-head">
      <div class="slide-box"></div>
      <div class="head-center">{{ title }}</div>
      <div class="slide-box head-title">
        {{ moment().format("YYYY-MM-DD HH:mm:ss") }} UTC+08:00
      </div>
    </div>
    <div class="contetn_left">
      <div class="left_item">
        <div class="box-content">
          <div class="item_title">
            <div class="title">通讯状态简报</div>
          </div>
          <div class="item_title_content">
            <div class="flex" style="width: 100%; height: 100%">
              <div class="flex-sub1" id="communicate"></div>
              <ul class="flex-sub1 communicate-list">
                <li
                  class="flex-aligns-justifyb item"
                  v-for="(item, index) in powerStation"
                  :key="index"
                >
                  <div class="flex-alignc left">
                    <div :style="{ backgroundColor: item.color }"></div>
                    <div>{{ item.name }}</div>
                  </div>
                  <div class="right">{{ item.value }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="left_item">
        <div class="box-content">
          <div class="item_title">
            <div class="title">报警状态简报</div>
          </div>
          <div class="item_title_content flex-alignc-justifyc">
            <ul class="flex-alignc cfff police-list">
              <li class="flex-sub1 flex-center item">
                <div class="border-box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="图层 1"
                    viewBox="0 0 19.66 20"
                    data-inject-url="https://pro.solarmanpv.com/static/icons/alarm.svg"
                    data-v-93dd90c6=""
                    class="vaM tra-fast w100pct h100pct dpB"
                  >
                    <path
                      d="M9.8 5.8a5 5 0 00-5 5V17h10.08v-6.19a5 5 0 00-5-5zm-5.81 3a.82.82 0 00-.64-1L.99 7.37A.832.832 0 10.66 9l2.33.44a.82.82 0 001-.64zm-.55-5.47l1.55 1.88a.82.82 0 001.27-1l-1.55-1.9a.82.82 0 00-1.44.63.81.81 0 00.17.39zM9.93 4a.82.82 0 00.84-.8V.84A.82.82 0 009.13.8v2.41a.84.84 0 000 .14.82.82 0 00.8.65zm3.78 1.52a.82.82 0 001.15-.07l1.59-1.81a.82.82 0 10-1.23-1.08l-1.58 1.81a.82.82 0 00.07 1.15zm5.95 2.95a.82.82 0 00-.93-.68l-2.38.36a.82.82 0 10.25 1.61l2.39-.36a.82.82 0 00.67-.93zm-3 10.37A1.16 1.16 0 0115.5 20H3.91a1.16 1.16 0 01-1.16-1.16 1.16 1.16 0 011.16-1.16H15.5a1.16 1.16 0 011.16 1.16z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <span class="mr-10">有报警电站</span>
                  <span class="c048F">{{ alarmStatusNum.alarmStationCount }}</span>
                </div>
              </li>
              <li class="flex-sub1 flex-center">
                <div class="border-box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="图层 1"
                    viewBox="0 0 12 12"
                    data-inject-url="https://pro.solarmanpv.com/static/icons/status-alarm.svg"
                    data-v-93dd90c6=""
                    class="vaM tra-fast w100pct h100pct dpB"
                  >
                    <path
                      d="M6 0a6 6 0 11-6 6 6 6 0 016-6zM3.72 3.35a.26.26 0 00-.19.08A3.63 3.63 0 002.5 6a3.58 3.58 0 001 2.54.26.26 0 00.19.08.27.27 0 00.19-.08.29.29 0 000-.39A3.1 3.1 0 013 6v-.25a3 3 0 01.86-1.92.3.3 0 000-.4.27.27 0 00-.14-.08zm4.56 0a.27.27 0 00-.19.08.3.3 0 000 .4A3.05 3.05 0 019 6a3.1 3.1 0 01-.87 2.16.29.29 0 000 .39.27.27 0 00.19.08.26.26 0 00.19-.08 3.66 3.66 0 000-5.09.26.26 0 00-.23-.11zm-3.75.84a.27.27 0 00-.19.08 2.45 2.45 0 000 3.41.25.25 0 00.38 0 .27.27 0 000-.39 1.9 1.9 0 010-2.63.27.27 0 000-.33v-.06h-.06a.28.28 0 00-.13-.08zm2.94 0a.28.28 0 00-.19.47 1.9 1.9 0 010 2.63.29.29 0 000 .39.25.25 0 00.38 0 2.45 2.45 0 000-3.41.27.27 0 00-.19-.08zM6 5a1 1 0 10.94 1A.95.95 0 006 5z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <span class="mr-10">无报警电站</span>
                  <span class="c048F">{{ alarmStatusNum.noAlarmStationCount }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="left_item">
        <div class="box-content">
          <div class="item_title">
            <div class="title">
              {{ moment().format("YYYY-MM") }}整体发电历史
            </div>
            <div class="time">当月发电量{{ monthHistoryTotal }} KWh</div>
          </div>
          <div class="item_title_content">
            <div id="powerHistoryMonth" style="width: 100%; height: 100%"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="contetn_right">
      <div class="right_item">
        <div class="box-content">
          <div class="item_title">
            <div class="title">整体发电概况</div>
          </div>
          <div class="item_title_content flex-alignc">
            <div class="flex-sub1 h100" style="position: relative">
              <div id="overview" style="width: 100%; height: 100%"></div>
              <div class="overview-text">
                <div v-if="showNum" class="num">
                  {{ powerResult.powerRate }}%
                </div>
                <div v-else class="flex-center">
                  <div>{{ (powerResult.allActivePower/1000).toFixed(2) }}KW</div>
                  <div>当前发电总功率</div>
                  <div class="line"></div>
                  <div>{{ (powerResult.allCapacity).toFixed(2) }}KWh</div>
                  <div>总装机容量</div>
                </div>
              </div>
            </div>

            <ul class="flex-sub1 overview-list">
              <li
                class="flex-aligns-justifyb"
                v-for="(item, index) in powerProfile"
                :key="index"
              >
                <div>{{ item.title }}</div>
                <div class="num">{{ item.num }} {{ item.unit }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right_item">
        <div class="box-content">
          <div class="item_title">
            <div class="title">
              {{ moment().format("YYYY-MM-DD") }}满发小时排名前5名
            </div>
          </div>
          <div class="item_title_content">
            <ul class="pd-20 h100 hour-list">
              <li class="flex-aligns-justifyb cfff">
                <div class="flex-sub1">电站名称</div>
                <div class="flex-sub1">地址</div>
                <div class="flex-sub1">满发小时(h)</div>
              </li>
              <li
                class="flex-aligns-justifyb c00e"
                v-for="(item, index) in hourData"
                :key="index"
              >
                <div class="flex-sub1 ellipsis">{{ item.stationName }}</div>
                <div class="flex-sub1 ellipsis">{{ item.address }}</div>
                <div class="flex-sub1 ellipsis">{{ item.fullHour }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right_item">
        <div class="box-content">
          <div class="item_title">
            <div class="title">{{ moment().format("YYYY") }}整体发电历史</div>
            <div class="time">当年发电量{{ yearHistoryTotal }} KWh</div>
          </div>
          <div class="item_title_content">
            <div id="powerHistoryYear" style="width: 100%; height: 100%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  statusEchartData,
  powerHistoryEchart,
  overviewEchartData,
} from "./data";
import EchartChinaWord from "@/components/EchartChinaWord1";
import moment from "moment";
import {
  stationStatus,
  allPowerHistory,
  powerOverview,
  dashboardFullHour,
  stationDistribution,
} from "@/api/dataBoard.js";
import { alarmStatus } from "@/api/apply.js";
export default {
  components: {
    EchartChinaWord,
  },
  data() {
    return {
      title: "",
      moment,
      // 电站状态简报
      powerStation: [
        {
          name: "有报警电站",
          value: "0",
          prop: "alarmStationCount",
          color: "rgb(0, 157, 246)",
        },
        {
          name: "部分设备离线电站",
          value: "0",
          prop: "partOffLineCount",
          color: "rgb(253, 186, 56)",
        },
        {
          name: "接入中电站",
          value: "0",
          prop: "join",
          color: "rgb(164, 68, 225)",
        },
        {
          name: "全部设备离线电站",
          value: "0",
          prop: "allOffLineCount",
          color: "rgb(73, 236, 227)",
        },
      ],
      totalStation: 0, // 电站总数
      // 报警状态
      alarmStatusNum: {
        alarmStationCount:0, // 有报警电站
        noAlarmStationCount:0, // 无报警电站 前端计算 总电站减去报警电站
      },
      // 发电概况数据
      powerProfile: [
        {
          title: "当日发电量",
          num: "0",
          unit: "kWh",
          color: "rgb(245, 251, 255)",
          prop: "dayPower",
        },
        {
          title: "当月发电量",
          num: "0",
          unit: "kWh",
          color: "rgb(255, 248, 247)",
          prop: "monthPower",
        },
        {
          title: "当年发电量",
          num: "0",
          unit: "KWh",
          color: "rgb(246, 245, 255)",
          prop: "yearPower",
        },
        {
          title: "累计发电量",
          num: "0",
          unit: "KWh",
          color: "rgb(245, 250, 246)",
          prop: "allPower",
        },
      ],
      powerResult: {}, // 整体发电历史详情
      monthHistoryTotal: 0, // 整体发电历史 月总量
      yearHistoryTotal: 0, // 整体发电历史 年总量

      showNum: true,
      hourData: [], // 满发小时排名前5名
    };
  },
  mounted() {
    this.title = this.$route.query.title;
    // 初始化echarts
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.startInit()
      this.intervalId ? clearInterval ( this.intervalId ) : ''
      this.intervalId = setInterval ( () => {
        this.startInit()
      }, 120000 );
    },

    // 封装函数，给定时任务调用
    startInit(){
      this.getStationStatus (); // 电站状态简报
      this.getAllPowerHistory ( 1 ); // 整体发电历史 1:月 2:年
      this.getPowerOverview (); // 整体发电概况
      this.getDashboardFullHour (); // 日满发小时排名
      this.getAllPowerHistory ( 2 ); // 整体发电历史 1:月 2:年
      this.initChinaMap (); // 初始化地图
    },

    // 电站状态简报
    getStationStatus() {
      stationStatus({}).then((res) => {
        res.data.noAlarmStationCount = res.data.count-res.data.alarmStationCount
        // 报警状态简报
        this.alarmStatusNum.alarmStationCount = res.data.alarmStationCount
        this.alarmStatusNum.noAlarmStationCount = res.data.noAlarmStationCount
        console.log(this.alarmStatusNum)
        console.log(this.alarmStatusNum.noAlarmStationCount)
        this.powerStation.forEach((item) => {
          for (let k in res.data) {
            if (k == item.prop) {
              item.value = res.data[k];
            }
          }
        });
        this.totalStation = res.data.count;
        var communicate = this.echarts.init(
          document.getElementById("communicate", null, {
            devicePixelRatio: window.devicePixelRatio,
          })
        );
        communicate.setOption(
          statusEchartData({
            powerStation: this.powerStation,
            totalStation: this.totalStation,
          })
        );
      });
    },

    // 整体发电历史 timeType 1:月 2:年
    getAllPowerHistory(timeType = 1) {
      let startTime, endTime;
      startTime =
        timeType == 1 ? moment().startOf("month") : moment().startOf("year");
      endTime =
        timeType == 1 ? moment().endOf("month") : moment().endOf("year");
      allPowerHistory({
        timeType,
        startTime: startTime.format("YYYY-MM-DD 00:00:00"),
        endTime: endTime.format("YYYY-MM-DD 00:00:00"),
      }).then((res) => {
        let xData = [],
          yData = [],
          total = 0;
        res.data.map((item) => {
          xData.push(...Object.keys(item));
          yData.push(...Object.values(item));
          for (let key in item) {
            total += item[key];
          }
        });
        total = total.toFixed(2);
        let legend = timeType == 1 ? "当月发电量" : "当年发电量";
        if (timeType == 1) {
          this.monthHistoryTotal = total;
          const powerHistoryMonth = this.echarts.init(
            document.getElementById("powerHistoryMonth", null, {
              devicePixelRatio: window.devicePixelRatio,
            })
          );
          powerHistoryMonth.setOption(
            powerHistoryEchart({ xData, yData }, "kWh", legend, total)
          );
        } else {
          this.yearHistoryTotal = total;
          const powerHistoryYear = this.echarts.init(
            document.getElementById("powerHistoryYear", null, {
              devicePixelRatio: window.devicePixelRatio,
            })
          );
          powerHistoryYear.setOption(
            powerHistoryEchart({ xData, yData }, "kWh", legend, total)
          );
        }
      });
    },

    // 整体发电概况
    getPowerOverview() {
      let that = this;
      powerOverview({}).then((res) => {
        this.powerResult = res.data;
        this.powerProfile.forEach((item) => {
          for (let k in res.data) {
            if (k == item.prop) {
              item.num = res.data[k];
            }
          }
        });
        setInterval(() => {
          that.showNum = !this.showNum;
        }, 3000);
        const overviewVm = this.echarts.init(
          document.getElementById("overview", null, {
            devicePixelRatio: window.devicePixelRatio,
          })
        );
        overviewVm.setOption(overviewEchartData(this.powerResult.powerRate));
      });
    },

    // 日满发小时排名
    getDashboardFullHour() {
      dashboardFullHour({
        createTime: moment().format("YYYY-MM-DD 00:00:00"),
      }).then((res) => {
        this.hourData = res.data.slice(0, 5);
      });
    },

    // 初始化地图 获取电站分布
    initChinaMap() {
      stationDistribution({}).then((res) => {
        var mapData = [];
        mapData = res.data.map((item) => {
          if (item.longitude) {
            item.longitude = item.longitude
              .replace(/\,/g, "")
              .replace(/\./g, "");
            item.longitude = Number(
              `${item.longitude.slice(0, 3)}.${item.longitude.slice(3)}`
            );
            item.latitude = item.latitude.replace(/\,/g, "").replace(/\./g, "");
            item.latitude = Number(
              `${item.latitude.slice(0, 2)}.${item.latitude.slice(2)}`
            );
          }
          return {
            name: item.stationName,
            value: [item.longitude, item.latitude],
          };
        });
        this.$nextTick(() => {
          this.$refs.EchartChinaWord.initEchartMap(mapData);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.screen-box {
  font-size: 20px;
}
.communicate-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 20px;
  .item {
    color: #efe4e4;
    .left {
      div:first-child {
        width: 20px;
        height: 20px;
        margin-right: 15px;
        border-radius: 5px;
      }
    }
    .right {
      color: #00eaff;
    }
  }
}
.peN {
  pointer-events: none;
}
.bg-map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 50%;
  background-size: cover;
}
.bg-map1 {
  background: url("../../assets/screen/screen2/mapShadow.png");
  background-size: cover;
  z-index: 4;
}
.bg-map2 {
  background: url("../../assets/screen/screen2/bg.png");
  background-size: cover;
  z-index: 1;
}
.bg-map3 {
  background: url("../../assets/screen/screen2/map2.png");
  background-size: cover;
  z-index: 3;
}
.screen-box {
  width: 100%;
  height: 100%;
  // background: url("../../assets/screen/screen2/bg.svg") center no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: hidden;
  z-index: 1;
  background: #081226;
  .mapclass {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .screen-head {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    background: url("../../assets/screen/screen2/header-top.png") no-repeat top;
    background-size: contain;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    // align-items: center;
    z-index: 100;
    .head-center {
      flex: 1;
      padding-bottom: 16px;
      align-self: center;
      font-family: PangMenZhengDao, Arial, "sans-serif";
      font-size: 34px;
      color: #d6eeff;
      text-align: center;
      text-shadow: 0 0 16px #d6eeff14;
      font-weight: 700;
    }
    .slide-box {
      width: 30%;
    }
    .head-title {
      text-align: right;
      font-size: 12px;
      color: #ffffff73;
    }
  }
  .contetn_left,
  .contetn_right {
    position: absolute;
    top: 0;
    width: 30%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 80px;
    z-index: 10;
  }
  .contetn_left {
    left: 0;
  }
  .contetn_right {
    right: 0;
  }

  .left_item,
  .right_item {
    position: relative;
    width: 100%;
    height: 32%;
  }
  .box-content {
    box-sizing: border-box;
    padding: 6px 16px 0;
    position: relative;
    width: 100%;
    height: 100%;

    .item_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      position: relative;
      height: 42px;
      line-height: 42px;
      background: url("../../assets/screen/screen2/header.svg") top left
        no-repeat;
      background-size: 100%;
      &::before {
        position: absolute;
        top: -4px;
        left: -7px;
        display: block;
        width: 12px;
        height: 12px;
        content: "";
        border: 2px #097efe solid;
        border-radius: 50%;
      }
      .title {
        font-size: 20px;
        font-weight: 700;
        color: #ffffffcc;
      }
      .time {
        font-size: 14px;
        // font-weight: 700;
        color: #ffffffcc;
      }
    }

    .item_title_content {
      height: calc(100% - 38px);
    }
  }
}
.police-list {
  .item {
    margin-right: 120px;
  }
  .border-box {
    position: relative;
    width: 100px;
    height: 100px;
    position: relative;
    box-sizing: border-box;
    border-radius: 50%;
    background: url("../../assets/screen/screen2/border-img.svg");
    background-size: 100% 100%;
    margin-bottom: 50px;
    &::before {
      content: "";
      inset: -5px;
      border: 1px dashed #3ebdff;
      position: absolute;
      border-radius: 50%;
      animation: roteBorder 6s linear infinite;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      fill: #fff;
    }
  }
}
.overview-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 16px;
  .num {
    font-size: 36px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 10px 0;
  }
}
.overview-list {
  color: #fff;
  padding-right: 20px;
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .num {
      color: #00eaff;
      font-weight: bold;
    }
  }
}
.hour-list {
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@keyframes roteBorder {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
