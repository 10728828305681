<template>
  <div class="qiu">
    <div id="china_map" ref="Map"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import "@/plugins/world"; // 引入世界地图
let nameMap = {
  Afghanistan: "阿富汗",
  Singapore: "新加坡",
  Angola: "安哥拉",
  Albania: "阿尔巴尼亚",
  "United Arab Emirates": "阿联酋",
  Argentina: "阿根廷",
  Armenia: "亚美尼亚",
  "French Southern and Antarctic Lands": "法属南半球和南极领地",
  Australia: "澳大利亚",
  Austria: "奥地利",
  Azerbaijan: "阿塞拜疆",
  Burundi: "布隆迪",
  Belgium: "比利时",
  Benin: "贝宁",
  "Burkina Faso": "布基纳法索",
  Bangladesh: "孟加拉国",
  Bulgaria: "保加利亚",
  "The Bahamas": "巴哈马",
  "Bosnia and Herzegovina": "波斯尼亚和黑塞哥维那",
  Belarus: "白俄罗斯",
  Belize: "伯利兹",
  Bermuda: "百慕大",
  Bolivia: "玻利维亚",
  Brazil: "巴西",
  Brunei: "文莱",
  Bhutan: "不丹",
  Botswana: "博茨瓦纳",
  "Central African Republic": "中非共和国",
  Canada: "加拿大",
  Switzerland: "瑞士",
  Chile: "智利",
  China: "中国",
  "Ivory Coast": "象牙海岸",
  Cameroon: "喀麦隆",
  "Democratic Republic of the Congo": "刚果民主共和国",
  "Republic of the Congo": "刚果共和国",
  Colombia: "哥伦比亚",
  "Costa Rica": "哥斯达黎加",
  Cuba: "古巴",
  "Northern Cyprus": "北塞浦路斯",
  Cyprus: "塞浦路斯",
  "Czech Republic": "捷克共和国",
  Germany: "德国",
  Djibouti: "吉布提",
  Denmark: "丹麦",
  "Dominican Republic": "多明尼加共和国",
  Algeria: "阿尔及利亚",
  Ecuador: "厄瓜多尔",
  Egypt: "埃及",
  Eritrea: "厄立特里亚",
  Spain: "西班牙",
  Estonia: "爱沙尼亚",
  Ethiopia: "埃塞俄比亚",
  Finland: "芬兰",
  Fiji: "斐",
  "Falkland Islands": "福克兰群岛",
  France: "法国",
  Gabon: "加蓬",
  "United Kingdom": "英国",
  Georgia: "格鲁吉亚",
  Ghana: "加纳",
  Guinea: "几内亚",
  Gambia: "冈比亚",
  "Guinea Bissau": "几内亚比绍",
  Greece: "希腊",
  Greenland: "格陵兰",
  Guatemala: "危地马拉",
  "French Guiana": "法属圭亚那",
  Guyana: "圭亚那",
  Honduras: "洪都拉斯",
  Croatia: "克罗地亚",
  Haiti: "海地",
  Hungary: "匈牙利",
  Indonesia: "印度尼西亚",
  India: "印度",
  Ireland: "爱尔兰",
  Iran: "伊朗",
  Iraq: "伊拉克",
  Iceland: "冰岛",
  Israel: "以色列",
  Italy: "意大利",
  Jamaica: "牙买加",
  Jordan: "约旦",
  Japan: "日本",
  Kazakhstan: "哈萨克斯坦",
  Kenya: "肯尼亚",
  Kyrgyzstan: "吉尔吉斯斯坦",
  Cambodia: "柬埔寨",
  Kosovo: "科索沃",
  Kuwait: "科威特",
  Laos: "老挝",
  Lebanon: "黎巴嫩",
  Liberia: "利比里亚",
  Libya: "利比亚",
  "Sri Lanka": "斯里兰卡",
  Lesotho: "莱索托",
  Lithuania: "立陶宛",
  Luxembourg: "卢森堡",
  Latvia: "拉脱维亚",
  Morocco: "摩洛哥",
  Moldova: "摩尔多瓦",
  Madagascar: "马达加斯加",
  Mexico: "墨西哥",
  Macedonia: "马其顿",
  Mali: "马里",
  Myanmar: "缅甸",
  Montenegro: "黑山",
  Mongolia: "蒙古",
  Mozambique: "莫桑比克",
  Mauritania: "毛里塔尼亚",
  Malawi: "马拉维",
  Malaysia: "马来西亚",
  Namibia: "纳米比亚",
  "New Caledonia": "新喀里多尼亚",
  Niger: "尼日尔",
  Nigeria: "尼日利亚",
  Nicaragua: "尼加拉瓜",
  Netherlands: "荷兰",
  Norway: "挪威",
  Nepal: "尼泊尔",
  "New Zealand": "新西兰",
  Oman: "阿曼",
  Pakistan: "巴基斯坦",
  Panama: "巴拿马",
  Peru: "秘鲁",
  Philippines: "菲律宾",
  "Papua New Guinea": "巴布亚新几内亚",
  Poland: "波兰",
  "Puerto Rico": "波多黎各",
  "North Korea": "北朝鲜",
  Portugal: "葡萄牙",
  Paraguay: "巴拉圭",
  Qatar: "卡塔尔",
  Romania: "罗马尼亚",
  Russia: "俄罗斯",
  Rwanda: "卢旺达",
  "Western Sahara": "西撒哈拉",
  "Saudi Arabia": "沙特阿拉伯",
  Sudan: "苏丹",
  "South Sudan": "南苏丹",
  Senegal: "塞内加尔",
  "Solomon Islands": "所罗门群岛",
  "Sierra Leone": "塞拉利昂",
  "El Salvador": "萨尔瓦多",
  Somaliland: "索马里兰",
  Somalia: "索马里",
  "Republic of Serbia": "塞尔维亚",
  Suriname: "苏里南",
  Slovakia: "斯洛伐克",
  Slovenia: "斯洛文尼亚",
  Sweden: "瑞典",
  Swaziland: "斯威士兰",
  Syria: "叙利亚",
  Chad: "乍得",
  Togo: "多哥",
  Thailand: "泰国",
  Tajikistan: "塔吉克斯坦",
  Turkmenistan: "土库曼斯坦",
  "East Timor": "东帝汶",
  "Trinidad and Tobago": "特里尼达和多巴哥",
  Tunisia: "突尼斯",
  Turkey: "土耳其",
  "United Republic of Tanzania": "坦桑尼亚",
  Uganda: "乌干达",
  Ukraine: "乌克兰",
  Uruguay: "乌拉圭",
  "United States": "美国",
  Uzbekistan: "乌兹别克斯坦",
  Venezuela: "委内瑞拉",
  Vietnam: "越南",
  Vanuatu: "瓦努阿图",
  "West Bank": "西岸",
  Yemen: "也门",
  "South Africa": "南非",
  Zambia: "赞比亚",
  Korea: "韩国",
  Tanzania: "坦桑尼亚",
  Zimbabwe: "津巴布韦",
  Congo: "刚果",
  "Central African Rep.": "中非",
  Serbia: "塞尔维亚",
  "Bosnia and Herz.": "波黑",
  "Czech Rep.": "捷克",
  "W. Sahara": "西撒哈拉",
  "Lao PDR": "老挝",
  "Dem.Rep.Korea": "朝鲜",
  "Falkland Is.": "福克兰群岛",
  "Timor-Leste": "东帝汶",
  "Solomon Is.": "所罗门群岛",
  Palestine: "巴勒斯坦",
  "N. Cyprus": "北塞浦路斯",
  Aland: "奥兰群岛",
  "Fr. S. Antarctic Lands": "法属南半球和南极陆地",
  Mauritius: "毛里求斯",
  Comoros: "科摩罗",
  "Eq. Guinea": "赤道几内亚",
  "Guinea-Bissau": "几内亚比绍",
  "Dominican Rep.": "多米尼加",
  "Saint Lucia": "圣卢西亚",
  Dominica: "多米尼克",
  "Antigua and Barb.": "安提瓜和巴布达",
  "U.S. Virgin Is.": "美国原始岛屿",
  Montserrat: "蒙塞拉特",
  Grenada: "格林纳达",
  Barbados: "巴巴多斯",
  Samoa: "萨摩亚",
  Bahamas: "巴哈马",
  "Cayman Is.": "开曼群岛",
  "Faeroe Is.": "法罗群岛",
  "IsIe of Man": "马恩岛",
  Malta: "马耳他共和国",
  Jersey: "泽西",
  "Cape Verde": "佛得角共和国",
  "Turks and Caicos Is.": "特克斯和凯科斯群岛",
  "St. Vin. and Gren.": "圣文森特和格林纳丁斯",
};
export default {
  data() {
    return {};
  },
  mounted() {
    this.initEchartMap()
  },
  methods: {
    //初始化中国地图
    initEchartMap1(echartData = []) {
      var mycontractMap = echarts.init(this.$refs.Map);
      var dataValue = echartData;

      let option = {
        // 鼠标悬浮提示框
        tooltip: {
          trigger: "item",
          borderColor: "#666", //区域边框颜色
          formatter: function (params) {
            if (params.name) {
              return (
                params.name +
                " : " +
                (isNaN(params.value) ? 0 : parseInt(params.value))
              );
            }
          },
        },

        visualMap: {
          min: 0, //最小值
          max: 1000, //最大值
          orient: "horizontal", //图例排列方向
          // orient: "vertical", //图例模式
          left: 26,
          bottom: 20,
          showLabel: true, //显示图例文本
          precision: 0, //数据展示无小数点
          itemWidth: 12, //图例宽度
          itemHeight: 12, //图例高度
          textGap: 10, //图例间距
          inverse: false, //数据反向展示
          hoverLink: true, //鼠标悬浮
          inRange: {
            //选中图例后背景半透明
            color: ["rgba(3,4,5,0.4)"],
            symbol: "rect", //更改图元样式
          },
          pieces: [
            {
              gt: 1001,
              label: ">1000",
              color: "#004bbc",
            },
            {
              gte: 500,
              lte: 1000,
              label: "500-1000",
              color: "#237bff",
            },
            {
              gte: 100,
              lte: 499,
              label: "100-499",
              color: "#35a9ff",
            },
            {
              gte: 10,
              lte: 99,
              label: "10-99",
              color: "#73c1ff",
            },
            {
              gte: 1,
              lte: 9,
              label: "1-9",
              color: "#b4deff",
            },
            {
              lte: 0,
              label: "0",
              color: "#d2ecf1",
            },
          ],
          textStyle: {
            color: "#fff",
            fontSize: 14, //图元字体大小
            fontWeight: 500,
          },
        },
        series: [
          {
            name: "World Population (2010)",
            type: "map",
            mapType: "world",
            zoom: 1.2, //地图大小
            roam: false, //禁止拖拽
            itemStyle: {
              normal: {
                areaColor: "#d2ecf1", //地图默认颜色
                borderWidth: 0.5, //边框宽度
                textStyle: {
                  color: "#fff", //默认文字颜色
                },
                borderColor: "#000", //地图边框颜色
              },
              emphasis: {
                areaColor: "#4306fe", //动态背景颜色
              },
            },
            select: {
              //地图选中颜色
              itemStyle: {
                areaColor: "#4306fe",
              },
            },
            label: {
              normal: {
                //静态的时候展示样式
                show: false, //是否显示地图名称
                textStyle: {
                  color: "#000", //颜色
                  fontSize: 14, //文字大小
                  fontFamily: "Arial",
                },
              },
              emphasis: {
                //动态展示的样式
                color: "#fff",
              },
            },
            data: [
              { name: "萨摩亚", value: 297 },
              { name: "乌克兰", value: 195 },
              { name: "越南", value: 315 },
              { name: "新西兰", value: 844 },
              { name: "墨西哥", value: 403 },
              { name: "美国", value: 263 },
              { name: "巴拿马", value: 268 },
              { name: "澳大利亚", value: 5 },
            ],
            nameMap: nameMap,
          },
          {
            name: "",
            type: "scatter",
            coordinateSystem: "geo",
            data: dataValue,
            symbol: "circle",
            symbolSize: 10,
            hoverSymbolSize: 10,
            tooltip: {
              formatter(value) {
                // return value.data.name + "<br/>" + "设备数：" + "22";
              },
              show: true,
            },
            encode: {
              value: 2,
            },
            label: {
              formatter: "{b}",
              position: "right",
              show: false,
            },
            itemStyle: {
              // color: "#e0eb40",
              color: "#fff",
              borderWidth: 3,
              borderColor: "#1be1df",
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //   },
            // },
            emphasis: {
              disabled: false,
              label: { //鼠标移入不显示name
                show: false
              },
              itemStyle: {
                areaColor: 'inherit',
                color: 'auto',
                borderColor: '#5AB0FE', borderWidth: 2
              }
            }
          },
        ],
      };
      mycontractMap.setOption(option);
      window.addEventListener("resize", function () {
        mycontractMap.resize();
      });
    },
    initEchartMap(echartData = []) {
      let mapDiv = document.getElementById("china_map");
      let myChart = echarts.init(mapDiv);

      var dataValue = echartData;
      // var data1 = dataValue.splice(0, 4);
      // dataValue = [
      //   {
      //     label: '123',
      //     value: [117.555328, 31.384124]
      //   }
      // ]
      var options;
      options = {
        tooltip: {
          show: false,
          triggerOn: "mousemove", //mousemove、click
          padding: 8,
          backgroundColor: "rgba(0,0,0,0.6)",
          borderWidth: 0,
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        geo: {
          map: "world",
          roam: true, // 一定要关闭拖拽
          zoom: 6,
          center: [102, 36], // 调整地图位置
          aspectScale: 0.8, //长宽比
          label: {
            normal: {
              show: true, //关闭省份名展示
              fontSize: "10",
              color: "#ffffff",
              formatter(v) {
                return v.name=='China'?'中国':''
              }
            },
            emphasis: {
              show: false,
            },
          },
          silent: true, // 取消高亮  //禁用地图的hover事件
          itemStyle: {
            normal: {
              areaColor: "#000001",
              borderColor: "#293878",
              borderWidth: 1, //设置外层边框
              // shadowBlur: 6,
              // shadowOffsetX: 0,
              // shadowOffsetY: 8,
              // shadowColor: 'rgba(1, 39, 44, 1)',
            },
            // normal: {
            //     shadowColor: 'rgba(1, 39, 44, 1)',
            //     shadowOffsetX: 0,
            //     shadowOffsetY: 8
            // },
            emphasis: {
              show: false,
              // areaColor: "#184cff",
              // shadowOffsetX: 0,
              // shadowOffsetY: 0,
              shadowBlur: 5,
              borderWidth: 0,
              // shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        series: [
          {
            name: "",
            type: "scatter",
            coordinateSystem: "geo",
            data: dataValue,
            symbol: "circle",
            symbolSize: 10,
            hoverSymbolSize: 10,
            tooltip: {
              formatter(value) {
                return value.data.name;
                return value.data.name + "<br/>" + "设备数：" + "22";
              },
              show: true,
            },
            encode: {
              value: 2,
            },
            // label: {
            //   formatter: "{b}",
            //   position: "right",
            //   show: true,
            // },
            itemStyle: {
              // color: "#e0eb40",
              color: "#fff",
              borderWidth: 3,
              borderColor: "#1be1df",
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //   },
            // },
            emphasis: {
              disabled: false,
              label: { //鼠标移入不显示name
                show: false
              },
              itemStyle: {
                areaColor: 'inherit',
                color: 'auto',
                borderColor: '#5AB0FE', borderWidth: 2
              }
            }
          },
          //下面是前五产生涟漪动画
          // {
          //   name: "Top 5",
          //   type: "effectScatter",
          //   coordinateSystem: "geo",
          //   data: data1,
          //   // symbolSize: 15,
          //   tooltip: {
          //     formatter(value) {
          //       // return value.data.name + "<br/>" + "设备数：" + "22";
          //     },
          //     show: true,
          //   },
          //   // encode: {
          //   //   value: 2,
          //   // },
          //   // showEffectOn: "render",
          //   // rippleEffect: {
          //   //   brushType: "stroke",
          //   //   color: "#e0eb40",
          //   //   period: 9,
          //   //   scale: 5,
          //   // },
          //   // hoverAnimation: true,
          //   label: {
          //     formatter: "{b}",
          //     position: "right",
          //     show: true,
          //   },
          //   itemStyle: {
          //     // color: "#e0eb40",
          //     color: "#fff",
          //     borderWidth: 2,
          //     borderColor: "#1be1df",
          //     // shadowBlur: 2,
          //     // shadowColor: "#333",
          //   },
          //   zlevel: 1,
          // },
        ],
      };
      myChart.setOption(options);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

  },
};
</script>

<style scoped>
.qiu {
  width: 100%;
  height: 100%;
}

#china_map {
  position: absolute;
  width: 40%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>
